@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Source Sans Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212!important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(100px + 2vmin);
  color: white;
  text-align: left;
}

.App-header h1 {
  font-family: 'Playfair Display';
  font-size: 4.5rem;
}

.App-header h5 {
  font-family: 'Source Sans Pro';
  font-weight: 300;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

section {
  padding: 8rem 0rem;
  color:#fff;
  background-color: #212121;
}

section h1 {
  font-family: 'Playfair Display';
}

.cards {
  background-color: #303030;
  padding: 20px;
  border-radius: 10px;
  font-family: 'Source Sans Pro';
  transition: all 0.5s;
  margin-top: 30px;
}

.cards:hover {
  box-shadow: -1px 1px #46D39E, -2px 2px #46D39E, -3px 3px #46D39E;
  transform: translate(3px, -3px);
}

.cards h2 {
  font-weight: 600;
}

.cards h6 {
  font-weight: 300;
  color: #46D39E;
}

.cards p {
  color: rgba(255, 255, 255, 0.6);
}

.cards .social-icon:hover {
  cursor: pointer;
  color: #46D39E;
}

.App-header .social-icon {
  color: #46D39E;
  cursor: pointer;
}

a {
  color: #fff!important;
}

.modal-content {
  background-color: #212121!important;
  color: #fff;
  border: none!important;
  font-family: 'Source Sans Pro';
}

.modal-header {
  border: none!important;
  padding-bottom: 0!important;
  font-weight: 600;
}

.modal-title {
  font-weight: 600;
}

.modal-body {
  padding-top: 0!important;
}

.modal-footer {
  border: none!important;
}

.close {
  color: #fff!important;
  opacity: 0.75!important;
}

.slick-prev {
  display:none!important;
}

.slick-next {
  display:none!important;
}

.resume-button:hover {
  background-color: #303030!important;
}

